import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import HorizontalDivider from "../../components/HorizontalDivider";
import Input from "../../components/Input";
import DisabledCam from "../../assets/disabledCam.svg";
import axios from "axios";
const Create = () => {
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  });

  const handleAllowAudioVideo = async () => {
    try {
      // Solicita permissão ao usuário e obtém o stream
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });
      setStream(mediaStream);

      setIsVideoEnabled(true);
    } catch (err) {
      console.error("Failed to access media devices:", err);
    }
  };

  const handleCreateMeet = async () => {
    try {
      const payload = {
        name: title,
        description: description, // Adicione uma descrição relevante aqui
        template_id: process.env.REACT_APP_TEMPLATE_ID
      };

      // Fazendo a requisição para criar uma nova sala
      const response = await axios.post(
        "https://api.100ms.live/v2/rooms",
        payload,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
          }
        }
      );

      const roomId = response.data.id;

      // Fazendo a requisição para buscar os Room Codes
      const roomCodeResponse = await axios.post(
        `https://api.100ms.live/v2/room-codes/room/${roomId}`,
        { room_id: roomId },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            "Content-Type": "application/json"
          }
        }
      );

      const roomCodeData = roomCodeResponse.data.data;

      const broadcasterCode = roomCodeData.find(
        (code) => code.role === "broadcaster"
      )?.code;

      if (broadcasterCode) {
        navigate(`/meet/${broadcasterCode}`);
      } else {
        console.error("Room Code not found for the created room.");
      }
    } catch (error) {
      console.error("Error creating room:", error);
    }
  };

  return (
    <div className="w-full">
      <div className="flex item-start gap-4 w-full">
        <div className="flex flex-col p-[10px] container  w-[50%]">
          <div className="flex flex-col h-full gap-2 justify-center">
            <p className="title flex items-center justify-center mt-10">
              Verify mic and cam
            </p>

            {/**
             *  <Input
              type="text"
              value={title}
              onChange={setTitle}
              required
              placeholder="Write meet title..."
            />
            <Input
              type="text"
              value={description}
              onChange={setDescription}
              required
              placeholder="Write meet description..."
            />
             */}
            <Button
              type="primary"
              value={"Allow audio and video"}
              onClick={handleAllowAudioVideo}
            ></Button>
            <div className="bg-[#595959] p-4 rounded-[4px] w-[70%] mx-auto mt-10">
              <div className="flex items-center gap-4">
                <span className="material-symbols-outlined text-red-300">
                  warning
                </span>
                <p className="mono text-white"> Remember if</p>
              </div>
              <p className="mono text-white">
                You need to grant the browser permission to access your camera
                and audio so that members can hear and see you, perform this
                action by clicking on the "Allow audio and video" button
              </p>
            </div>
          </div>
        </div>
        <div className="relative">
          <HorizontalDivider />
        </div>
        <div className="flex flex-col h-full items-end justify-end">
          <p className="default-text items-end justify-center mono title p-2 w-full  flex">
            Preview
          </p>
          <div className="flex p-4 w-full rounded-[4px]">
            {isVideoEnabled ? (
              <video ref={videoRef} autoPlay className="w-[493px] h-[322px]" />
            ) : (
              <img src={DisabledCam} alt="Disabled Camera" />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center w-[50%] mt-4 justify-between">
        <p className="text-sm mt-2 ">
          About more{" "}
          <Link
            className="text-blue-500 hover:opacity-70"
            target="_blank"
            to={"https://alpha.kinera.network/custom-tools"}
          >
            Kinera tools
          </Link>{" "}
        </p>
        <Button
          type="primary"
          value={"Create meet"}
          onClick={handleCreateMeet}
        ></Button>
      </div>
    </div>
  );
};

export default Create;
