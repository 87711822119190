import React, { useState, useEffect, useContext } from "react";
import {
  useHMSActions,
  useHMSStore,
  selectHMSMessages,
  useHMSMessage
} from "@100mslive/react-sdk";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import KineContext from "../../../context/KineContext";

const Chat = () => {
  const [message, setMessage] = useState("");
  const { loggeduser } = useContext(KineContext);
  const messages = useHMSStore(selectHMSMessages);
  const hmsActions = useHMSActions();
  const handleSend = async () => {
    if (message.trim().length > 0) {
      await hmsActions.sendBroadcastMessage(message);
      setMessage("");
    }
  };

  return (
    <div className="flex flex-col gap-4 p-2">
      <div className="messages-container">
        {messages.map((msg, index) => (
          <div key={index} className="">
            {loggeduser && loggeduser.name && <b>{loggeduser.name}: </b>}

            {msg.message}
          </div>
        ))}
      </div>
      <div className="flex items-end flex-col  gap-4">
        <div className="w-full">
          <Input
            type="text"
            value={message}
            onChange={setMessage}
            placeholder="Type your message..."
          />
        </div>
        <Button onClick={handleSend} value="Send" type="primary"></Button>
      </div>
    </div>
  );
};

export default Chat;
