import React from "react";

const Button = ({ type, value, icon, onClick, disabled }) => {
  return (
    <button className={`${type} btn`} onClick={onClick} disabled={disabled}>
      {value}
    </button>
  );
};

export default Button;
