import React, { useEffect, useState } from "react";
import {
  selectPeers,
  selectPeersScreenSharing,
  useHMSStore,
  useHMSActions,
  selectIsAllowedToPublish,
  selectLocalPeer
} from "@100mslive/react-sdk";
import Peer from "../../Peer";
import { ScreenTile } from "../../ScreenTile";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import Chat from "./components/Chat";
import Controlls from "./components/Controls";
import { PeerVideo } from "./components/PeerVideo";
import AdminPeer from "./components/AdminPeer";
import { PersonIcon } from "@100mslive/react-icons";

const Stream = () => {
  const { roomCode } = useParams();

  const peers = useHMSStore(selectPeers);
  const presenters = useHMSStore(selectPeersScreenSharing);
  const hmsActions = useHMSActions();
  const role = localStorage.getItem("KineraStreamRole") || "viewer"; // Buscar role do localStorage
  const localPeer = useHMSStore(selectLocalPeer);
  const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
  const [admin, setAdmin] = useState(false);
  const [fetch, setFetch] = useState(true);
  useEffect(() => {
    const joinConference = async () => {
      setFetch(true);
      try {
        const param = roomCode
          ? roomCode
          : window.location.pathname.split("/")[2];
        const authToken = await hmsActions.getAuthTokenByRoomCode({
          roomCode: param
        });

        if (role === "broadcaster") {
          setAdmin(true);
        }
        setFetch(false);
        try {
          await hmsActions.join({
            userName: "teste",
            authToken
          });
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        console.error(e);
      }
    };

    joinConference();
  }, []);

  const renderAdmin = () => {
    return peers
      .filter((peer) => peer.roleName === "broadcaster")
      .map(
        (peer, key) =>
          key < 1 && (
            <AdminPeer key={peer.id} peer={peer} presenters={presenters} />
          )
      );
  };

  const renderViewerCount = () => {
    return peers.filter((peer) => peer.roleName === "viewer-realtime").length;
  };

  return (
    <div className="container-stream   w-full flex flex-col relative gap-2">
      <div className="w-full flex ">
        {presenters && presenters.length ? (
          <div className="w-[70%]">
            {presenters.map(
              (peer, key) =>
                key < 1 && <PeerVideo key={"screen" + peer.id} peer={peer} />
            )}
            <div className="flex w-[70%]">{renderAdmin()}</div>
          </div>
        ) : (
          ""
        )}

        <div
          className={`${
            presenters && presenters.length ? "w-[30%]" : "w-full"
          } flex  flex-row `}
        >
          {!presenters.length ? (
            <div className="flex w-[70%]">{renderAdmin()}</div>
          ) : (
            ""
          )}
          <div
            className={` ${
              presenters && presenters.length ? "w-full" : "w-[30%]"
            } flex justify-end`}
          >
            <Chat />
          </div>
        </div>
      </div>
      <div className="viewer-count">
        <p className="mono">Viewers: {renderViewerCount()}</p>
      </div>
      {admin && <Controlls />}
    </div>
  );
};

export default Stream;
