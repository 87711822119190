import React, { useContext } from "react";
import { useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button.jsx";
import Input from "../../components/Input.jsx";
import Image from "../../assets/kinera_.webp";
import { useHMSActions } from "@100mslive/react-sdk";
import Divider from "../../components/Divider.jsx";
import HorizontalDivider from "../../components/HorizontalDivider.jsx";
import KineContext from "../../context/KineContext.js";
const Home = () => {
  const { loggeduser } = useContext(KineContext);
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [viewerLink, setViewerLink] = useState("");
  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate();

  const hmsActions = useHMSActions();

  const handleSubmitCode = async (e) => {
    e.preventDefault();
    // use room code to fetch auth token
    const authToken = await hmsActions.getAuthTokenByRoomCode({
      roomCode: code
    });
    navigate(`/meet/${code}`);
    try {
      await hmsActions.join({
        userName: "teste",
        authToken
      });
      navigate(`/meet/${code}`);
    } catch (e) {
      console.error(e);
    }
  };

  const navigateToCreate = () => {
    navigate("/create");
  };

  return (
    <div>
      <div className="flex item-start gap-4 w-full">
        <div className="flex flex-col p-[10px]   w-[50%]">
          {loggeduser && loggeduser.name && (
            <p className="mono default-text w-full items-end justify-end flex">
              You are logged in as {loggeduser.name}
            </p>
          )}

          <div className="flex flex-col  gap-20 justify-end">
            <p className="title  mt-10">
              Create meetings or broadcasts to connect with other users
            </p>
            {(!loggeduser || !loggeduser.name) && (
              <p className="mono">You need login to create meet</p>
            )}
            <div className="flex gap-4 items-center w-full container p-4">
              <Button
                type="primary"
                value={"Create meet"}
                onClick={navigateToCreate}
                disabled={!loggeduser || !loggeduser.name}
              ></Button>
              <p className="default-text">or</p>
              <Input
                type="text"
                value={code}
                onChange={setCode}
                required
                placeholder="connect via code"
              />
              <Button
                type="subtle"
                value={"Connect"}
                onClick={handleSubmitCode}
                disabled={
                  !code || !code.length || !loggeduser || !loggeduser.name
                }
              ></Button>
            </div>
            <Divider />
            <div className="w-auto">
              <Button
                type="primary"
                value={"Create Transmission [soon]"}
              ></Button>
            </div>
          </div>
        </div>

        <div className="flex flex-col  gap-4 h-full w-[50%] items-center">
          <p className="default-text container mono p-2">
            Kinera Chain Stream is a P2P streaming tool that enables direct
            communication between participants. It allows efficient transmission
            of audio, video, and data without relying on central servers,
            providing enhanced privacy and reduced latency.
          </p>
          <img src={Image} width="300px" />
        </div>
      </div>
      <p className="text-sm mt-2 ml-2">
        About more{" "}
        <Link
          className="text-blue-500 hover:opacity-70"
          target="_blank"
          to={"https://alpha.kinera.network/custom-tools"}
        >
          Kinera tools
        </Link>{" "}
      </p>
    </div>
  );
};

export default Home;
